import React from "react";
import PropTypes from "prop-types";
import BookResult from "./BookResult";

class BookResults extends React.Component {
  render() {
    return (
      <div className="box alt">
        <div className="row gtr-50 gtr-uniform">
          {this.props.searchResults.map((book, i) => (
            <BookResult
              updateBookResult={this.props.updateBookResult}
              bookData={book}
              key={i}
              i={i}
            />
          ))}
          <div className="col-4">
            <button
              value="Next"
              onClick={this.props.loadMore}
              style={{
                display:
                  this.props.searchResults.length === 0
                    ? "none"
                    : "inline-block"
              }}
            >
              Load More
            </button>
          </div>
        </div>
      </div>
    );
  }
}
BookResults.propTypes = {
  loadMore: PropTypes.func.isRequired,
  allResults: PropTypes.array.isRequired,
  updateBookResult: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired
};
export default BookResults;
