import React from "react";

const Row = ({ title, author, gender, pubdate, desc, price, status }) => (
  <tbody>
    <tr>
      <td>{title}</td>
      <td>{author}</td>
      <td>{gender}</td>
      <td>{pubdate}</td>
      <td>{desc}</td>
      <td>{price}</td>
      <td>{status}</td>
    </tr>
  </tbody>
);

/*
  Table component written as an ES6 class
*/
export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.books
    };

    // http://reactkungfu.com/2015/07/why-and-how-to-bind-methods-in-your-react-component-classes/
    // bind the context for compareBy & sortBy to this component instance
    this.compareBy.bind(this);
    this.sortBy.bind(this);
  }

  //this function takes a key and then compares the values of the key
  compareBy(key) {
    return function(a, b) {
      // console.object(a[key]);
      console.log(a[key]);
      console.log(b[key]);
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.data];
    arrayCopy.sort(this.compareBy(key));
    this.setState({ data: arrayCopy });
  }

  render() {
    const rows = this.state.data.map(rowData => {
      // console.table(rowData);
      return <Row {...rowData} />;
    });

    return (
      <table className="table">
        <thead className="header">
          <tr>
            <th onClick={() => this.sortBy("title")}>Title</th>
            <th onClick={() => this.sortBy("author")}>Author</th>
            <th onClick={() => this.sortBy("gender")}>Gender</th>
            <th onClick={() => this.sortBy("pubdate")}>Published Date</th>

            <th onClick={() => this.sortBy("desc")}>Description</th>
            <th onClick={() => this.sortBy("price")}>Price</th>
            <th onClick={() => this.sortBy("status")}>Status</th>
          </tr>
        </thead>
        {rows}
      </table>
    );
  }
}
