import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { parseXMLResponse } from "../helpers";
import BookResults from "./BookResults";
import base from "../base";

const apiKey = process.env.REACT_APP_API_KEY;

class AddBook extends React.Component {
  titleRef = React.createRef();
  authorRef = React.createRef();
  urlRef = React.createRef();

  state = {
    suggestions: [],
    searchText: "",
    query: "",
    error: "",
    fetchingData: false,
    searchResults: [],
    allResults: [],
    min: 0,
    max: 3,
  };

  componentDidMount() {
    this.suggestionsRef = base.syncState("suggestions", {
      context: this,
      state: "suggestions",
    });
  }

  componentWillUnmount() {
    //prevents memory leak by unbinding when component is unmounted
    base.removeBinding(this.suggestionsRef);
  }

  loadMore = (e) => {
    const minimum = this.state.max + 1;
    const maximum = minimum + 3;

    const newResults = this.state.allResults.slice(minimum, maximum);
    this.setState({
      searchResults: newResults,
      min: minimum,
      max: maximum,
    });
  };

  resetForm = (event) => {
    event.currentTarget.reset();
  };

  addBook = (event) => {
    event.preventDefault();

    const book = {
      title: this.titleRef.current.value,
      author: this.authorRef.current.value,
      url: this.urlRef.current.value,
      image: null,
    };
    //validation to make sure form does not submit on empty book
    if (!book.title || !book.author || !book.url) {
      console.log("ERROR");
      return;
    }
    this.addBookSuggestion(book);

    event.currentTarget.reset();
    this.setState({
      searchResults: [],
      allResults: [],
    });
  };

  getInfo = (searchValue) => {
    this.setState({ searchText: searchValue, query: searchValue });
    const requestUri =
      `https://cors-anywhere.herokuapp.com/` +
      `https://www.goodreads.com/search/index.xml?key=${apiKey}&q=${searchValue}`;
    axios
      .get(requestUri)
      .then((res) => {
        var searchResults = parseXMLResponse(res.data);
        this.setState({
          fetchingData: false,
          allResults: searchResults,
          searchResults: searchResults.slice(0, 3),
        });
      })
      .catch((error) => {
        this.setState({
          error: error.toString(),
          fetchingData: false,
        });
      });
  };

  //populate input fields with selected goodreads book
  updateBookResult = (e, bookData) => {
    e.preventDefault();
    const url = `https://www.goodreads.com/book/show/${bookData.best_book.id}`;
    this.authorRef.current.value = bookData.best_book.author.name;
    this.urlRef.current.value = url;
    this.titleRef.current.value = bookData.best_book.title;
  };

  clearResults = (e) => {
    e.preventDefault();
    this.setState({
      searchResults: [],
      allResults: [],
      searchText: "",
      query: "",
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({
      query: this.titleRef.current.value,
      searchText: this.titleRef.current.value,
      fetchingData: true,
      searchResults: [],
      allResults: [],
    });
    this.getInfo(this.titleRef.current.value);
  };

  deleteSuggestion = (book) => {
    console.log(book);
    console.log(this.state.uid);
    let bookSuggestions = [...this.state.suggestions];
    const bookIndex = bookSuggestions.findIndex(
      (dbBook) => dbBook.title === book.title && dbBook.uid === this.state.uid
    );
    bookSuggestions = [
      ...bookSuggestions.slice(0, bookIndex),
      ...bookSuggestions.slice(bookIndex + 1),
    ];
    this.setState({ suggestions: bookSuggestions });
    console.log("delete suggestion");
  };

  addBookSuggestion = (book) => {
    let bookSuggestions = [...this.state.suggestions];
    //TODO, ensure user isn't adding the same book twice.
    //TODO maybe change to filter?
    let userSuggestions = bookSuggestions.map((dbBook) =>
      dbBook.uid === this.props.user.uid ? book.title : ""
    );
    const hasTitle = userSuggestions.some((title) => title === book.title);
    if (hasTitle) {
      this.setState({ error: "User already suggested this book" });
      console.log("user already suggested this  book");
      return;
    }

    var uid = this.state.user.uid;
    book.uid = uid;
    book.bookid = `book${Date.now()}`;
    bookSuggestions.push(book);
    this.setState({ suggestions: bookSuggestions });
  };

  renderList = (key) => {
    let isOwner = false;
    const book = this.state.suggestions[key];
    if (book.uid === this.props.user.uid) {
      isOwner = true;
    }
    return (
      <tr key={key} style={this.listStyle}>
        <td>{book.title}</td>
        <td>{book.author}</td>
        <td>{book.url}</td>
        <td>{book.desc}</td>
        {isOwner ? (
          <td onClick={() => this.deleteSuggestion(book)}>✖</td>
        ) : (
          <td />
        )}
      </tr>
    );
  };

  renderError = () => {
    console.log("error: " + this.state.error);
    if (this.state.error !== "") {
      return (
        <div>
          <h2>Error</h2>
          <p>Error details: {this.state.error}</p>
        </div>
      );
    } else {
      return null;
    }
  };
  render() {
    const bookIds = Object.keys(this.state.suggestions);
    return (
      <React.Fragment>
        <div id="main">
          <div className="inner">
            <section>
              <div className="row">
                <div>{this.renderError()}</div>
                <div className="col-12">
                  <h2>Add a book suggestion</h2>
                  <form method="post" onSubmit={this.addBook}>
                    <div className="row gtr-uniform">
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          ref={this.titleRef}
                          name="title"
                          placeholder="Book Title"
                          onBlur={this.handleInputChange}
                          onChange={this.clearResults}
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          ref={this.authorRef}
                          name="author"
                          placeholder="Author"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <BookResults
                          loadMore={this.loadMore}
                          allResults={this.state.allResults}
                          updateBookResult={this.updateBookResult}
                          searchResults={this.state.searchResults}
                        />
                      </div>

                      <div className="col-12">
                        <input
                          type="text"
                          ref={this.urlRef}
                          name="url"
                          placeholder="goodreads.com url"
                        />
                      </div>

                      <div className="col-12">
                        <ul className="actions">
                          <li>
                            <button type="submit" className="primary">
                              Submit
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <hr className="major" />
              <div className="row">
                <div className="col-12">
                  <div className="table-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Author</th>
                          <th>Url</th>
                        </tr>
                      </thead>
                      <tbody>{bookIds.map(this.renderList)}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AddBook.propTypes = {
  user: PropTypes.object.isRequired,
  books: PropTypes.array.isRequired,
};

export default AddBook;
