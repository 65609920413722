import React, { Component } from "react";
import PropTypes from "prop-types";

import MainBook from "./MainBook";
import Book from "./Book";
import Header from "./Header";

class App extends Component {
  onBookSelect = key => {
    this.props.history.push(`/book/${key}`);
  };

  render() {
    if (!this.props.books) {
      return `<p>test</p>`;
    } else {
      return (
        <div id="main">
          <div className="inner">
            <Header routes={this.props.match} />
            <MainBook details={this.props.selectedBook} />
            <section>
              <header className="major">
                <h2>Past Books</h2>
              </header>
              <div className="posts">
                {Object.keys(this.props.books).map(key => (
                  <Book
                    key={key}
                    index={key}
                    details={this.props.books[key]}
                    onBookSelect={this.onBookSelect}
                  />
                ))}
              </div>
            </section>
          </div>
        </div>
      );
    }
  }
}

App.propTypes = {
  user: PropTypes.shape({
    hasAccess: PropTypes.bool,
    subscribed: PropTypes.bool,
    subscriptionEmail: PropTypes.string,
    uid: PropTypes.string
  }).isRequired,
  books: PropTypes.array.isRequired,
  selectedBook: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default App;
