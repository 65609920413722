const allowedUsers = [
  "anja.gusev@gmail.com",
  "princehenry4@hotmail.com",
  "hcrump5@gmail.com",
];

export function checkIfValidUser(email) {
  //console.log(email);

  return allowedUsers.includes(email);
}

export function XMLToJson(XML) {
  const allNodes = new Array(...XML.children);
  const jsonResult = {};
  allNodes.forEach((node) => {
    if (node.children.length) {
      jsonResult[node.nodeName] = XMLToJson(node);
    } else {
      jsonResult[node.nodeName] = node.innerHTML;
    }
  });
  return jsonResult;
}

// parse string xml received from goodreads api
export function parseXMLResponse(response) {
  const parser = new DOMParser();
  const XMLResponse = parser.parseFromString(response, "application/xml");
  const parseError = XMLResponse.getElementsByTagName("parsererror");

  if (parseError.length) {
    return "error";
    // this.setState({
    //   error: "There was an error fetching results.",
    //   fetchingData: false
    // });
  } else {
    const XMLresults = new Array(...XMLResponse.getElementsByTagName("work"));
    const searchResults = XMLresults.map((result) => XMLToJson(result));
    return searchResults;

    // this.setState({ fetchingData: false }, () => {
    //   this.props.setResults(searchResults);
    // });
  }
}

export const filterBooksByGender = (books, gender) => {
  return books.filter((x) => x.gender === gender);
};

export const getPercentageOfGender = (books, gender) => {
  const filteredBooks = filterBooksByGender(books, gender);
  const percentage = ((filteredBooks.length / books.length) * 100).toFixed(2);
  return `${percentage}%`;
};

//TODO get count of how many books published each year and display counts from most popular year

export const countByYear = (books) => {
  var countedYears = books.reduce((yearBooks, book) => {
    if (book.pubdate in yearBooks) {
      yearBooks[book.pubdate]++;
    } else {
      yearBooks[book.pubdate] = 1;
    }
    return yearBooks;
  }, {});
  return countedYears;
};

export const countByYearArray = (books) => {
  var countedYears = books.reduce((yearBooks, book, index) => {
    let foundBook;
    foundBook = yearBooks.findIndex((b) => b.year === book.pubdate);
    if (foundBook >= 0) {
      yearBooks[foundBook].count++;
    } else {
      yearBooks.push({ year: book.pubdate, count: 1 });
    }
    return yearBooks;
  }, []);
  return countedYears;
};

export const sortYearsArray = (yearArray) => {
  console.log(yearArray.length);
  const yearArrayCopy = [...yearArray];
  //oldest to newest
  return yearArrayCopy.sort((a, b) => (a.year > b.year ? 1 : -1));
};

//https://stackoverflow.com/questions/1069666/sorting-javascript-object-by-property-value
export const sortYears = (obj) => {
  // Object.keys(obj).map(a => {
  //   console.log(a);
  //   return a;
  // });

  return Object.keys(obj).sort(function (a, b) {
    return obj[a] - obj[b];
  });
};
