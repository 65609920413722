import React, { Component } from "react";
import {
  getPercentageOfGender,
  countByYearArray,
  sortYearsArray,
} from "../helpers";
import Table from "./Table";
export default class Stats extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      sortedArray: [],
      percentageOfMale: "0%",
      percentageOfFemale: "0%",
      totalBooks: 0,
    };
    console.log("rendering another time");
  }

  componentWillMount() {
    console.log("rendering again");

    const books = [...this.props.books];
    console.log("books length", books.length);
    const malePercent = getPercentageOfGender(books, "male");
    const femalePercent = getPercentageOfGender(books, "female");
    const countArray = countByYearArray(books);

    const sortedArray = sortYearsArray(countArray);
    console.log(sortedArray.length);
    this.setState({
      sortedArray,
      percentageOfMale: malePercent,
      percentageOfFemale: femalePercent,
      totalBooks: books.length,
    });
    console.log("set state");
  }

  //this assumes that every book has the same properties
  createHeadings = (books) => {
    if (books.length > 0) {
      return Object.keys(books[0]);
    }
    return [];
  };

  createRows = (books) => {
    return books.map((book) =>
      // console.log(Object.values(book));
      Object.values(book)
    );
  };

  render() {
    // console.log(this.createHeadings(this.props.books));
    // console.log(this.createRows(this.props.books));
    console.log("rendering");
    return (
      // <div id="main">
      //   <div className="inner">
      //     <section>
      //       <Table books={this.props.books} />
      //     </section>
      //   </div>
      // </div>
      <React.Fragment>
        <div id="main">
          <div className="inner">
            <section>
              <div>
                <p>
                  <strong>Total Books Read Count:</strong>
                  {this.state.totalBooks}
                </p>
                {this.state.sortedArray.map((item) => (
                  <p key={item.year}>
                    <strong>{item.year}</strong> Count: {item.count}
                  </p>
                ))}

                <p>Percentage of Books By Men: {this.state.percentageOfMale}</p>
                <p>
                  Percentage of Books by Female: {this.state.percentageOfFemale}
                </p>
              </div>
            </section>
            <section>
              <Table books={this.props.books} />
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
