import React from "react";
import PropTypes from "prop-types";
class Subscribe extends React.Component {
  subscribeRef = React.createRef();
  subscribeUser = event => {
    event.preventDefault();
    const emailAddress = this.subscribeRef.current.value;
    this.props.subscribeUser(emailAddress);
    event.currentTarget.reset();
  };

  render() {
    return (
      <section id="subscribe" className="alt">
        <form method="post" onSubmit={this.subscribeUser}>
          <input
            type="text"
            ref={this.subscribeRef}
            name="query"
            id="query"
            placeholder="your email address"
          />
          <button type="submit">+ Subscribe +</button>
        </form>
      </section>
    );
  }
}

Subscribe.propTypes = {
  subscribeUser: PropTypes.func.isRequired
};

export default Subscribe;
