import React from "react";
import PropTypes from "prop-types";

const BookResult = ({ bookData, i, updateBookResult }) => {
  const bookTitle = bookData.best_book.title;
  let displayTitle = bookTitle
    .split(" ")
    .slice(0, 4)
    .join(" ");
  if (bookTitle.length > displayTitle.length) {
    displayTitle += "...";
  }

  return (
    <div className="col-4">
      <div className="image fit">
        <label>
          <input
            type="radio"
            name="test"
            value={bookData.best_book.title}
            // checked={i === 0 ? true : false}
            onChange={e => updateBookResult(e, bookData)}
          />
          <img
            src={bookData.best_book.image_url}
            alt={bookData.best_book.title}
          />
        </label>
      </div>
      {/* <span className="image fit">
        <img src={bookData.best_book.image_url} alt="" />
      </span> */}
    </div>
  );
};

BookResult.propTypes = {
  updateBookResult: PropTypes.func.isRequired,
  bookData: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired
};
export default BookResult;
