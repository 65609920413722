import React from "react";
import PropTypes from "prop-types";
import Header from "./Header";

class SingleBook extends React.Component {
  goBack = () => {
    this.props.history.push(`/`);
  };
  render() {
    console.log(this.props.match);
    const { params } = this.props.match;
    const id = params.bookId;
    const { image, title, author, desc, url } = this.props.books[id];
    return (
      <React.Fragment>
        <div id="main">
          <div className="inner">
            <Header goBack={this.goBack} routes={this.props.match} />
            <section id="banner">
              <div className="content">
                <header>
                  <h1>{title}</h1>
                  <p>by {author}</p>
                </header>
                <p>{desc}</p>
                <ul className="actions">
                  <li>
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button big"
                    >
                      Learn More
                    </a>
                  </li>
                </ul>
              </div>
              <span className="image object">
                <img
                  src={image ? image : "/assets/images/pic01.jpg"}
                  alt={title}
                />
              </span>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
SingleBook.propTypes = {
  books: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
export default SingleBook;
