import React from "react";

class Book extends React.Component {
  render() {
    const { image, title, author, desc, url } = this.props.details;
    return (
      <article>
        <a href={url} className="image">
          <img src={image ? image : "/assets/images/pic01.jpg"} alt={title} />
        </a>
        <h3>
          {title} <span className="author">{author}</span>
        </h3>
        <p>{desc}</p>
        <ul className="actions">
          <li>
            <button
              onClick={() => this.props.onBookSelect(this.props.index)}
              className="button"
            >
              More
            </button>
          </li>
        </ul>
      </article>
    );
  }
}

export default Book;
