import React from "react";
import PropTypes from "prop-types";
class ToggleSideBar extends React.Component {
  //TODO change a tag to button if possible
  render() {
    return (
      <a
        href="#"
        className={this.props.showSideBar ? "toggle" : "toggle inactive"}
        onClick={this.props.toggleSideBar}
      ></a>
    );
  }
}

ToggleSideBar.propTypes = {
  showSidebar: PropTypes.bool,
  toggleSideBar: PropTypes.func.isRequired,
};

export default ToggleSideBar;
