import React from "react";

class MainBook extends React.Component {
  render() {
    const { image, title, author, desc, url } = this.props.details;

    return (
      <section id="banner">
        <div className="content">
          <header>
            <span className="this-month">This month:</span> <br />
            <h1> {title}</h1>
            <p>by {author}</p>
          </header>
          <p>{desc}</p>
          <ul className="actions">
            <li>
              <a
                href={url}
                rel="noopener noreferrer"
                target="_blank"
                className="button big"
              >
                Learn More
              </a>
            </li>
          </ul>
        </div>
        <span className="image object">
          <img src={image} alt={title} />
        </span>
      </section>
    );
  }
}

export default MainBook;
