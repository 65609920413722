import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import Subscribe from "./Subscribe";
import Login from "./Login";
import ToggleSideBar from "./ToggleSideBar";
import Search from "./Search";
import onClickOutside from "react-onclickoutside";

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.updatePredicate = this.updatePredicate.bind(this);
  }
  searchRef = React.createRef();
  state = {
    showSideBar: false,
    isDesktop: false,
    searchResults: "",
    queryResults: [],
  };

  componentWillMount() {
    //collapse sidebar if navigation changes on mobile
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.isDesktop) return;
      if (this.state.showSideBar) {
        let showSideBar = !this.state.showSideBar;
        this.setState({ showSideBar });
      }
    });
  }
  componentDidMount() {
    if (window.innerWidth > 900) {
      this.setState({ showSideBar: true });
    }
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    this.unlisten();
  }

  updatePredicate() {
    this.setState({
      isDesktop: window.innerWidth > 900,
    });
  }

  toggleSideBar = (e) => {
    e.preventDefault();
    if (this.state.showSideBar) {
      this.setState({ showSideBar: false });
    } else {
      this.setState({ showSideBar: true });
    }
  };

  renderLink = (key) => {
    const currentBook = this.props.books[key];
    return (
      <Link to={`/book/${key}`} key={key}>
        {currentBook.title}
      </Link>
    );
  };
  renderSearchLink = (book) => {
    return (
      <li>
        <Link to={`/book/${book.key}`} key={book.key}>
          {book.title}
        </Link>
      </li>
    );
  };
  /**
   * Solution from https://github.com/Pomax/react-onclickoutside
   */
  handleClickOutside = (e) => {
    if (this.state.isDesktop) return;
    if (this.state.showSideBar) {
      let showSideBar = !this.state.showSideBar;
      this.setState({ showSideBar });
    }
    console.log("onClickOutside() method called");
  };
  findMatches = (wordToMatch, books) => {
    if (wordToMatch === "") {
      return [];
    }
    console.log(books);
    console.log("wordToMatch " + wordToMatch);
    return books.filter((book) => {
      const regex = new RegExp(wordToMatch, "gi");
      return (
        book.title.match(regex) ||
        book.desc.match(regex) ||
        book.author.match(regex)
      );
      //here we need to figure out if the city or state matches what was searched
    });
  };
  displayMatches = () => {
    console.log(this.searchRef.current.value);
    const matchArray = this.findMatches(
      this.searchRef.current.value,
      this.props.books
    );
    console.log(matchArray.length);
    this.setState({ queryResults: matchArray });
  };

  render() {
    // const isDesktop = this.state.isDesktop;
    const hasSubscribed = this.props.user.subscribed;
    let subscribeForm;
    if (!this.props.user.uid) {
      subscribeForm = null;
    } else if (!hasSubscribed) {
      subscribeForm = <Subscribe subscribeUser={this.props.subscribeUser} />;
    } else {
      subscribeForm = (
        <div>
          <Link to={`/addbook`}>Add Book Suggestion</Link>
          <br />
          <p style={{ marginTop: "1em" }}>You have already subscribed</p>
        </div>
      );
    }

    let addBookSection;
    if (!this.props.user.uid) {
      addBookSection = null;
    } else {
      addBookSection = (
        <li>
          <Link to={`/addbook`}>Add Book Suggestion</Link>
        </li>
      );
    }

    const bookIds = Object.keys(this.props.books);
    const logout = <button onClick={this.props.logout}>Log Out!</button>;
    return (
      <React.Fragment>
        <ToggleSideBar
          toggleSideBar={this.toggleSideBar}
          showSideBar={this.state.showSideBar}
          isDesktop={this.state.isDesktop}
        />

        <div
          id="sidebar"
          style={{ display: this.state.showSideBar ? "block" : "none" }}
        >
          <div className="inner ignore-react-onclickoutside">
            {/* <!-- Search --> */}
            <Search
              queryResults={this.state.queryResults}
              displayMatches={this.displayMatches}
              searchRef={this.searchRef}
            />
            {/* <!-- Menu --> */}
            <nav id="menu" className="ignore-react-onclickoutside">
              <header className="major ignore-react-onclickoutside">
                <h2>Menu</h2>
              </header>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                {bookIds.map(this.renderLink)}
                {addBookSection}
              </ul>
            </nav>
            {subscribeForm}
            {this.props.user.uid ? (
              <section className="ignore-react-onclickoutside">
                <header className="major ignore-react-onclickoutside">
                  <h2>Your account</h2>
                </header>

                <ul className="contact ignore-react-onclickoutside">
                  <li className="fa-home">{this.props.user.displayName}</li>
                </ul>
                {logout}
              </section>
            ) : (
              <Login
                className="ignore-react-onclickoutside"
                authenticate={this.props.authenticate}
              />
            )}

            {/* <!-- Footer --> */}
            <footer id="footer ignore-react-onclickoutside">
              <p className="copyright">
                &copy; Untitled. All rights reserved. Demo Images:
                <a href="https://unsplash.com">Unsplash</a>. Design:
                <a href="https://html5up.net">HTML5 UP</a>.
              </p>
            </footer>
          </div>
          {/* <a href="#" className="toggle" onClick={this.toggleSidebar}>
            Toggle
          </a> */}
        </div>
      </React.Fragment>
    );
  }
}
SideBar.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  books: PropTypes.array.isRequired,
  subscribeUser: PropTypes.func.isRequired,
  authenticate: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};
export default withRouter(onClickOutside(SideBar));
