import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import firebase from "firebase";
import base, { firebaseApp } from "../base";
import booksArray from "../booksarray";
import App from "./App";
import SingleBook from "./SingleBook";
import AddBook from "./AddBook";
import NotFound from "./NotFound";
import { checkIfValidUser } from "../helpers";
import SideBar from "./SideBar";
import Stats from "./Stats";

class Router extends React.Component {
  state = {
    books: booksArray,
    mainbook: booksArray[booksArray.length - 1],
    selectedBook: booksArray[booksArray.length - 1],
    test: {},
    user: {
      hasAccess: false,
      subscribed: false,
      subscriptionEmail: null,
      uid: null,
    },
    uid: null,
  };

  componentDidMount() {
    //ref in firebase is a reference to a piece of data
    //we are going to sync it with a specific collection
    //now whenever we change the state for "books" this change will reflect in the db

    this.ref = base.syncState("books", { context: this, state: "books" });

    //uncomment the code below  when you want to update the database to reflect any changes to array file
    //this.updateBooks();
    // firebase.database()
    //   .ref("books")
    //   .set(this.state.books);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //sync user to database
        this.userRef = base.syncState(`users/${user.uid}`, {
          context: this,
          state: "user",
        });

        this.authHandler({ user });
      }
    });
    //to prevent memory leak, use componentWillUnmount so as soon as the component is unmounting,
  }
  componentWillMount() {
    //adding index property to book JSON
    let updatedBooks = [...this.state.books];
    updatedBooks = updatedBooks.map((book, i) => {
      book.index = i;
      //console.log(book);
      return book;
    });
    this.setState({ books: updatedBooks });
  }

  componentWillUnmount() {
    //prevents memory leak by unbinding when component is unmounted
    base.removeBinding(this.ref);
    base.removeBinding(this.userRef);
    base.removeBinding(this.suggestionRef);
  }
  updateBooks = () => {
    //    uncomment the code below  when you want to update the database to reflect any changes to array file
    firebase.database().ref("books").set(this.state.books);
  };

  logout = async () => {
    //console.log("Logging out!");
    await firebase.auth().signOut();
    base.removeBinding(this.userRef);
    base.removeBinding(this.ref);
    base.removeBinding(this.suggestionRef);

    const emptyUser = {
      user: {
        hasAccess: false,
        subscribed: false,
        subscriptionEmail: null,
        uid: null,
      },
    };
    this.setState({ user: emptyUser, uid: null });
  };

  authHandler = async (authData) => {
    console.log(authData);
    //1. Look up the current store in the firebase database
    if (checkIfValidUser(authData.user.email)) {
      const userData = {
        uid: authData.user.uid,
        hasAccess: true,
        displayName: authData.user.displayName,
        email: authData.user.email,
      };
      //check if user already exists within db
      const dbuser = await base.fetch(`users/${authData.user.uid}`, {
        context: this,
      });
      if (!dbuser.uid) {
        //if no saved user, post to users/uid
        await base.post(`users/${authData.user.uid}`, {
          data: userData,
        });
      } else {
        //console.log("database user already exists");
      }
      this.setState({
        uid: authData.user.uid,
        user: userData,
      });
    } else {
      //TO DO - add message letting user know they cannot login because they are not part of the club
      this.logout();
    }
  };

  authenticate = (provider) => {
    const authProvider = new firebase.auth.FacebookAuthProvider();
    firebaseApp
      .auth() //gives us connection to the auth portion of firebase
      .signInWithPopup(authProvider)
      .then(this.authHandler); //once someone signs in, we do this
  };

  subscribeUser = (email) => {
    const user = { ...this.state.user };
    user.subscribed = true;
    user.subscribedEmail = email;
    this.setState({ user });
  };

  render() {
    return (
      <BrowserRouter>
        <div id="wrapper">
          <Switch>
            <Route
              exact
              path="/"
              render={(routeProps) => (
                <App
                  user={this.state.user}
                  books={this.state.books}
                  selectedBook={this.state.selectedBook}
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/book/:bookId"
              render={(routeProps) => (
                <SingleBook {...routeProps} books={this.state.books} />
              )}
            />
            <Route
              path="/addbook"
              render={(routeProps) => (
                <AddBook user={this.state.user} books={this.state.books} />
              )}
            />
            <Route
              path="/stats"
              render={(routeProp) => <Stats books={this.state.books} />}
            />
            <Route component={NotFound} />
          </Switch>
          <SideBar
            user={this.state.user}
            logout={this.logout}
            books={this.state.books}
            subscribeUser={this.subscribeUser}
            authenticate={this.authenticate}
            history={this.history}
          />
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
