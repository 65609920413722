import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
class Header extends React.Component {
  buttonStyle = {
    marginRight: "10px"
  };
  renderButton = () => {
    if (this.props.routes.path === "/") {
      return null;
    } else {
      return (
        <button onClick={this.props.goBack} style={this.buttonStyle}>
          Back
        </button>
      );
    }
  };

  render() {
    return (
      <header id="header">
        <Link to={`/`} className="logo">
          <strong>Book Club</strong>
        </Link>
        {this.renderButton()}
      </header>
    );
  }
}

Header.propTypes = {
  routes: PropTypes.object.isRequired
};

export default Header;
