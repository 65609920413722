// This is just some sample data so you don't have to think of your own!
const booksarray = [
  {
    key: "book1",
    title: "Small Fry",
    author: "Lisa Brennan-Jobs ",
    gender: "female",
    pubdate: 2018,
    url:
      "https://www.goodreads.com/book/show/39218044-small-fry?from_search=true",
    origimage: "../assets/images/small-fry.jpg",
    image: "../assets/images/scaled/books_small-fry-scale_416.jpg",
    cloudinary: "v1545866649/books/small-fry.jpg",
    desc:
      "Small Fry is Lisa Brennan-Jobs's poignant story of a childhood spent between two imperfect but extraordinary homes. Scrappy, wise, and funny, young Lisa is an unforgettable guide through her parents' fascinating and disparate worlds.",
    price: 1724,
    status: "available",
  },
  {
    key: "book2",
    title: "The Woman In The Window",
    author: "A.J. Finn",
    gender: "male",
    pubdate: 2018,
    url:
      "https://www.goodreads.com/book/show/40389527-the-woman-in-the-window?ac=1&from_search=true",
    origimage: "../assets/images/woman-in-the-window.jpg",
    image: "../assets/images/scaled/books_WomanInTheWindow-scale_416.jpg",
    cloudinary: "v1545866649/books/TheWomanInTheWindow.jpg",
    desc:
      "Anna Fox spends her day drinking wine, watching old movies, and spying on her neighbors. But when she sees something she shouldn't, Anna's world begins to crumble.",
    price: 3200,
    status: "available",
  },

  {
    key: "book3",
    title: "The Wife Between Us",
    author: "Greer Hendricks & Sarah Pekkanen",
    gender: "female",
    pubdate: 2018,
    url:
      "https://www.goodreads.com/book/show/34189556-the-wife-between-us?from_search=true",
    origimage: "../assets/images/the-wife-between-us.jpg",
    image:
      "../assets/images/scaled/books_the-wife-between-us-greer-hendricks-scale_416.jpg",
    cloudinary: "v1545866649/books/the-wife-between-us-2.jpg",
    desc:
      "A New York Times top-ten bestseller. 'A fiendishly clever thriller in the vein of Gone Girl and The Girl on the Train. This one will keep you guessing.' ",
    price: 1684,
    status: "unavailable",
  },

  {
    key: "book4",
    title: "Halfway To The Grave",
    author: "Jeaniene Frost",
    gender: "female",
    pubdate: 2007,
    url:
      "https://www.goodreads.com/book/show/1421990.Halfway_to_the_Grave?ac=1&from_search=true",
    origimage: "../assets/images/grave.jpeg",
    image: "../assets/images/scaled/books_halfway-to-the-grave-1-scale_416.jpg",
    cloudinary: "v1549999798/books/halfway-to-the-grave-1.jpg",
    desc:
      "Half-vampire Catherine Crawfield is going after the undead with a vengeance, hoping that one of these deadbeats is her father—the one responsible for ruining her mother's life. Then she's captured by Bones, a vampire bounty hunter, and is forced into an unholy partnership. ",
    price: 1129,
    status: "available",
  },

  {
    key: "book5",
    title: "The Immortalists",
    author: "Chloe Benjamin",
    gender: "female",
    pubdate: 2018,
    url:
      "https://www.goodreads.com/book/show/30288282-the-immortalists?from_search=true",
    origimage: "../assets/images/immortalists.jpg",
    image: "../assets/images/scaled/books_immortalists-scale_416.jpg",
    cloudinary: "v1549999817/books/immortalists.jpg",
    desc:
      "A sweeping novel of remarkable ambition and depth, The Immortalists probes the line between destiny and choice, reality and illusion, this world and the next. It is a deeply moving testament to the power of story, the nature of belief, and the unrelenting pull of familial bonds.",
    price: 4234,
    status: "available",
  },

  {
    key: "book6",
    title: "Warlight",
    author: "Michael Ondaatje",
    gender: "male",
    pubdate: 2018,
    url: "https://www.goodreads.com/book/show/35657511-warlight",
    origimage: "../assets/images/Warlight.jpg",
    image: "../assets/images/scaled/books_warlight-big-scale_416.jpg",
    cloudinary: "v1554413615/books/warlight-big.jpg",
    desc:
      "A mesmerizing new novel that tells a dramatic story set in the decade after World War II through the lives of a small group of unexpected characters and two teenagers whose lives are indelibly shaped by their unwitting involvement.",
    price: 1453,
    status: "available",
  },
  {
    key: "book7",
    title: "The President Is Missing",
    author: "Bill Clinton & James Patterson",
    gender: "male",
    pubdate: 2018,
    url:
      "https://www.goodreads.com/book/show/35097255-the-president-is-missingt",
    origimage: "../assets/images/president-is-missing.jpg",
    image: "../assets/images/scaled/books_president-is-missing-scale_416.jpg",
    cloudinary: "v1554412843/books/president-is-missing.jpg",
    desc:
      "As the novel opens, a threat looms. Enemies are planning an attack of unprecedented scale on America. Uncertainty and fear grip Washington. There are whispers of cyberterror and espionage and a traitor in the cabinet. The President himself becomes a suspect, and then goes missing...",
    price: 1453,
    status: "available",
  },
  {
    key: "book8",
    title: "Educated",
    author: "Tara Westover",
    gender: "female",
    pubdate: 2018,
    url: "https://www.goodreads.com/book/show/35133922-educated",
    origimage: "../assets/images/educated.jpg",
    image: "../assets/images/scaled/books_educated-scale_416.jpg",
    cloudinary: "v1559865856/books/educated.jpg",
    desc:
      "Tara Westover was seventeen the first time she set foot in a classroom. Born to survivalists in the mountains of Idaho, she prepared for the end of the world by stockpiling home-canned peaches and sleeping with her 'head-for-the-hills bag...",
    price: 1453,
    status: "available",
  },
  {
    key: "book9",
    title: "Shoe Dog",
    author: "Philip Knight",
    gender: "male",
    pubdate: 2018,
    url: "https://www.goodreads.com/book/show/27220736-shoe-dog",
    origimage: "../assets/images/shoedog.jpg",
    image: "../assets/images/scaled/books_shoedog-scale_416.jpg",
    cloudinary: "v1559866032/books/shoedog.jpg",
    desc:
      "In this candid and riveting memoir, for the first time ever, Nike founder and CEO Phil Knight shares the inside story of the company’s early days as an intrepid start-up and its evolution into one of the world’s most iconic, game-changing, and profitable brands.",
    price: 1453,
    status: "available",
  },
  {
    key: "book10",
    title:
      "Maybe You Should Talk to Someone: A Therapist, Her Therapist, and Our Lives Revealed",
    author: "Lori Gottlieb",
    gender: "female",
    pubdate: 2019,
    url: "https://www.goodreads.com/book/show/37570546",
    origimage: "../assets/images/maybeyoushouldtalk.jpg",
    image: "../assets/images/scaled/books_maybeyoushouldtalk-scale_416.jpg",
    cloudinary: "v1564101253/books/maybeyoushouldtalk.jpg",
    desc:
      "From a New York Times best-selling author, psychotherapist, and national advice columnist, a hilarious, thought-provoking, and surprising new book that takes us behind the scenes of a therapist's world--where her patients are looking for answers (and so is she).",
    price: 1453,
    status: "available",
  },
  {
    key: "book11",
    title: "Three Women",
    author: "Lisa Taddeo",
    gender: "female",
    pubdate: 2019,
    url: "https://www.goodreads.com/book/show/42201100-three-women",
    origimage: "../assets/images/three-women.jpg",
    image: "../assets/images/scaled/books_three-women-scale_416.jpg",
    cloudinary: "v1569282607/books/three-women.jpg",
    desc:
      "Desire as we’ve never seen it before: a riveting true story about the sex lives of three real American women, based on nearly a decade of reporting.",
    price: 1453,
    status: "available",
  },
  {
    key: "book12",
    title: "Winter In Paradise",
    author: "Elin Hilderbrand",
    gender: "female",
    pubdate: 2018,
    url: "https://www.goodreads.com/book/show/31933069-winter-in-paradise",
    origimage: "../assets/images/winter_in_paradise.jpg",
    image: "../assets/images/scaled/books_winter_in_paradise-scale_416.jpg",
    cloudinary: "v1572101807/books/winter_in_paradise.jpg",
    desc:
      "When a Midwestern businessman is killed in a helicopter crash in the Caribbean, his wife and sons learn that he had a secret life.",
    price: 1453,
    status: "available",
  },
  {
    key: "book13",
    title: "Where the Crawdads Sing",
    author: "Delia Owens",
    gender: "female",
    pubdate: 2018,
    url: "https://www.goodreads.com/book/show/36809135-where-the-crawdads-sing",
    origimage: "../assets/images/where-the-crawdads.jpg",
    image: "../assets/images/scaled/books_where-the-crawdads-scale_416.jpg",
    cloudinary: "v1572101807/books/winter_in_paradise.jpg",
    desc:
      "Where the Crawdads Sing is at once an exquisite ode to the natural world, a heartbreaking coming-of-age story, and a surprising tale of possible murder. Owens reminds us that we are forever shaped by the children we once were, and that we are all subject to the beautiful and violent secrets that nature keeps.",
    price: 1453,
    status: "available",
  },
  {
    key: "book14",
    title: "A Man Called Ove",
    author: "Fredrik Backman",
    gender: "male",
    pubdate: 2014,
    url: "https://www.goodreads.com/book/show/18774964-a-man-called-ove",
    origimage: "../assets/images/man-called-ove.jpg",
    image: "../assets/images/scaled/man-called-ove-scale_416.jpg",
    cloudinary: "v1579738912/books/man-called-ove.jpg",
    desc:
      "A grumpy yet loveable man finds his solitary world turned on its head when a boisterous young family moves in next door.",
    price: 1453,
    status: "available",
  },
  {
    key: "book15",
    title: "Catch and Kill: Lies, Spies, and a Conspiracy to Protect Predators",
    author: "Ronan Farrow",
    gender: "male",
    pubdate: 2019,
    url: "https://www.goodreads.com/book/show/46650277-catch-and-kill",
    origimage: "../assets/images/catch-and-kill.jpg",
    image: "../assets/images/scaled/catch-and-kill-scale_416.jpg",
    cloudinary: "v1582073352/books/catch-and-kill.jpg",
    desc:
      "In a dramatic account of violence and espionage, Pulitzer Prize-winning investigative reporter Ronan Farrow exposes serial abusers and a cabal of powerful interests hell-bent on covering up the truth, at any cost.",
    price: 1453,
    status: "available",
  },
  {
    key: "book16",
    title: "All the Ugly and Wonderful Things",
    author: "Bryn Greenwood",
    gender: "female",
    pubdate: 2016,
    url:
      "https://www.goodreads.com/book/show/26114135-all-the-ugly-and-wonderful-things",
    origimage: "../assets/images/ugly-and-beautiful-things.jpg",
    image: "../assets/images/scaled/ugly-and-beautiful-things-scale_416.jpg",
    cloudinary: "v1582073352/books/ugly-and-beautiful-things.jpg",
    desc:
      "A beautiful and provocative love story between two unlikely people and the hard-won relationship that elevates them above the Midwestern meth lab backdrop of their lives.",
    price: 1453,
    status: "available",
  },
  {
    key: "book17",
    title: "Normal People",
    author: "Sally Rooney",
    gender: "female",
    pubdate: 2019,
    url: "https://www.goodreads.com/book/show/41057294-normal-people",
    origimage: "../assets/images/normal_people.jpg",
    image: "../assets/images/scaled/normal_people-scale_416.jpg",
    cloudinary: "v1591139044/books/normal_people.jpg",
    desc:
      "Sally Rooney brings her brilliant psychological acuity and perfectly spare prose to a story that explores the subtleties of class, the electricity of first love, and the complex entanglements of family and friendship. .",
    price: 1453,
    status: "available",
  },
   {
    key: "book18",
    title: "The Seven Husbands of Evelyn Hugo",
    author: "Taylor Jenkins Reid ",
    gender: "female",
    pubdate: 2017,
    url: "https://www.goodreads.com/book/show/32620332-the-seven-husbands-of-evelyn-hugo",
    origimage: "../assets/images/seven-husbands.jpg",
    image: "../assets/images/scaled/seven-husbands-scale_416.jpg",
    cloudinary: "v1651073930/books/seven-husbands.jpg",
    desc:
      "Aging and reclusive Hollywood movie icon Evelyn Hugo is finally ready to tell the truth about her glamorous and scandalous life. But when she chooses unknown magazine reporter Monique Grant for the job, no one is more astounded than Monique herself. Why her? Why now?",
    price: 1453,
    status: "available",
  },
  {
    key: "book19",
    title: "People We Meet On Vacation",
    author: "Emily Henry",
    gender: "female",
    pubdate: 2021,
    url: "https://www.goodreads.com/book/show/32620332-the-seven-husbands-of-evelyn-hugo",
    origimage: "../assets/images/people_on_vacation.jpg",
    image: "../assets/images/scaled/people_on_vacation-scale_416.jpg",
    cloudinary: "v1651074106/books/people_on_vacation.jpg",
    desc:
      "Two best friends. Ten summer trips. One last chance to fall in love.",
    price: 1453,
    status: "available",
  },
];

export default booksarray;
