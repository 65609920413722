import React from "react";
import { Link } from "react-router-dom";

class Search extends React.Component {
  render() {
    return (
      <section id="search" className="alt">
        <form method="post" action="#">
          <input
            type="text"
            name="query"
            id="query"
            placeholder="Search"
            onChange={this.props.displayMatches}
            ref={this.props.searchRef}
          />
          <ul style={{ paddingLeft: `1em` }} className="alt">
            {this.props.queryResults.map(book => {
              return (
                <li>
                  <Link to={`/book/${book.index}`} key={book.key}>
                    {book.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </form>
      </section>
    );
  }
}
export default Search;
